


































































import { Vue, Component } from "vue-property-decorator";
import Users, { User, UserStatus, Invite, UserRole } from "@/store/users";
import * as R from "ramda";
import FancyButton from "@/components/FancyButton.vue";

@Component({
  name: 'CompanyView',
  components: {
    FancyButton
  }
})
export default class extends Vue {
  valid: boolean = false;
  model = {
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    title: "",
    status: UserStatus.ACTIVE,
    role: UserRole.COMPANY
  };
  originalModel: any = {};
  headers = [
    { text: 'Email', value: 'email' },
    { text: 'Invite sent', value: 'sent' },
    { text: 'Subscriber', value: 'subscriber' },
  ];
  invites: Invite[] = [];
  inviteEmails = [] as string[];
  inviteDialog = false;
  inviteLoading = false;
  inviteError = '';
  editContact = false;
  editName = false;

  userStatuses = [
    { text: "Active", value: "ACTIVE" },
    { text: "Inactive", value: "INACTIVE" }
  ];

  error = "";
  isLoading = false;

  fieldRule = [
    (v: any) => !!v || 'This field is required',
  ];
  emailRule = [
    (v: any) => {
      if(v.length > 0) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || 'Invalid e-mail.';
      } else { 
        return !!v || 'This field is required';
      }
    },
  ];
  get isEdit() {
    return !!this.$route.params.id;
  }

  get id() {
    return this.$route.params.id;
  }

  async mounted() {
    this.isLoading = true;
    if (this.isEdit) {
      try {
        const response = await Users.fetchOne({ id: this.id });
        const fetchedUser = response.data as User;
        (this.model as any) = R.clone(fetchedUser);
        this.originalModel = R.clone(fetchedUser);
        const invites = await Users.fetchInvites({ id: this.id });
        this.invites = R.clone(invites);
      } catch (err) {
        this.error = err.message;
      }
    } else {
      this.editName = true;
      this.editContact = true;
    }
    this.isLoading = false;
  }

  submitForm () {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.valid) {
      this.save()
    }
  }

  async save() {
    this.isLoading = true;
    try {
      if (this.isEdit) {
        await this.saveEdited();
      } else {
        await this.create();
      }
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  async create() {
    const { name, email, phoneNumber, title, role } = this.model;

    await Users.create({
      name,
      email,
      phoneNumber,
      title,
      role
    });

    this.$router.push("/companies");
  }

  get changes() {
    const retUndef = (arg: any) => (arg ? arg : undefined);
    const eq = (a1: any, a2: any) => (R.equals(a1, a2) ? false : a1);

    return R.mergeWith(R.compose(retUndef, eq), this.model, this.originalModel);
  }

  get changed() {
    return !Object.keys(this.changes).every(key => this.changes[key] === undefined);
  }

  async saveEdited() {
    await Users.update({ id: this.id, data: this.changes });

    window.location.reload();
  }

  async sendInvites() {
    this.inviteLoading = true;
    try {
      if (!this.inviteEmails.length) {
        throw Error("Please specify at least one email");
      }
      await Users.sendInvites({ id: this.id, emails: this.inviteEmails });
      this.inviteError = '';
      this.inviteDialog = false;
      this.isLoading = true;
      window.location.reload();
    } catch (err) {
      this.inviteError = err.message;
    }
    this.inviteLoading = false;
  }
}
